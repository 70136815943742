import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import {
  connectStateResults,
  connectHits,
  Highlight,
  Index,
  Snippet,
} from 'react-instantsearch-dom';
import classnames from 'classnames';

import useMediaQuery from '../../../hooks/useMediaQuery';

import './SearchResult.css';

const ContentPreview = ({ activeHit, onResultClick }) => {
  const hitToPreview = activeHit;

  if (!hitToPreview) {
    return false;
  }

  return (
    <>
      <h2>
        <Highlight attribute="title" hit={hitToPreview} tagName="mark" />
      </h2>
      <p>
        <Highlight attribute="description" hit={hitToPreview} tagName="mark" />
      </p>

      <div>
        {!!hitToPreview.tableOfContents &&
          !!hitToPreview.tableOfContents.items && (
            <>
              <p className="SearchResult__separator">On this page</p>
              <ol className="SearchResult__table-of-contents">
                {hitToPreview.tableOfContents.items.map(
                  (item, index) =>
                    item.title && (
                      <li key={index}>
                        <Link
                          onClick={onResultClick}
                          to={`${hitToPreview.url}${item.url}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    )
                )}
              </ol>
            </>
          )}
      </div>
    </>
  );
};

const HitCount = connectStateResults(
  ({ searchResults, searchState, error }) => {
    if (error) {
      return (
        <div className="SearchResult__no-results">
          There was an error: <mark>“{error.message}”</mark>
        </div>
      );
    }

    const hitCount = searchResults && searchResults.nbHits;
    if (hitCount > 0) {
      return false;
    }

    return (
      <div className="SearchResult__no-results">
        No results for <mark>“{searchState.query}”</mark>.
      </div>
    );
  }
);

const Hits = connectHits(
  ({ activeHit, hits, index, isPreviewVisible, onResultClick, setActiveHit }) => {
    if (!hits?.length) {
      return false;
    }

    return (
      <>
        <ul className="ais-Hits-list" data-insights-index={index.name}>
          {hits.map((hit) => (
            <li
              className="ais-Hits-item"
              onMouseEnter={() => {
                setActiveHit(hit);
              }}
              data-insights-object-id={hit.objectID}
              data-insights-position={hit.__position}
              data-insights-query-id={hit.__queryID}
            >
              <PageHit
                activeHit={activeHit}
                hit={hit}
                isPreviewVisible={isPreviewVisible}
                onClick={onResultClick}
              />
            </li>
          ))}
        </ul>
      </>
    );
  }
);

const HitsInIndex = ({
  activeHit,
  index,
  isPreviewVisible,
  onResultClick,
  setActiveHit,
}) => (
  <Index indexName={index.name}>
    <div className="SearchResult__separator">{index.name}</div>
    <Hits
      activeHit={activeHit}
      index={index}
      isPreviewVisible={isPreviewVisible}
      onResultClick={onResultClick}
      setActiveHit={setActiveHit}
    />
    <HitCount />
  </Index>
);

const PageHit = React.memo(({ activeHit, hit, isPreviewVisible, onClick }) => {
  const isHitActive =
    !!activeHit && hit.objectID === activeHit.objectID && !!isPreviewVisible;

  return (
    <Link
      className={classnames(`SearchResult__hit`, {
        'SearchResult__hit--active': isHitActive,
      })}
      onClick={onClick}
      to={hit.url}
    >
      <div>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </div>
      <div>
        <Snippet attribute="excerpt" hit={hit} tagName="mark" />
      </div>
    </Link>
  );
});

const ResultsList = connectStateResults(
  ({
    activeHit,
    indices,
    isPreviewVisible,
    onResultClick,
    setActiveHit,
    ...rest
  }) => {
    const { allSearchResults, searching, isSearchStalled } = rest;

    // Set first result as the `activeHit`.
    useEffect(() => {
      if (!searching && !isSearchStalled && !activeHit && !!allSearchResults) {
        const flattenedHits = indices
          .map((index) => allSearchResults[index.name])
          .reduce((acc, indexResults) => {
            if (indexResults?.hits?.length) {
              acc.push(...indexResults.hits);
            }
            return acc;
          }, []);

        if (flattenedHits.length) {
          setActiveHit(flattenedHits[0]);
        }
      }
    }, [
      activeHit,
      allSearchResults,
      indices,
      isSearchStalled,
      searching,
      setActiveHit,
    ]);

    return indices.map((index) => (
      <HitsInIndex
        activeHit={activeHit}
        index={index}
        isPreviewVisible={isPreviewVisible}
        key={index.name}
        onResultClick={onResultClick}
        setActiveHit={setActiveHit}
      />
    ));
  }
);

const SearchResult = ({
  activeHit,
  className,
  indices,
  onResultClick,
  setActiveHit,
}) => {
  const isPreviewVisible = useMediaQuery(`(min-width: 960px)`);

  const _setActiveHit = (...args) => {
    if (isPreviewVisible) {
      setActiveHit(...args);
    }
  };

  return (
    <div className={classnames(`SearchResult`, className)}>
      <div className="SearchResult__results">
        <ResultsList
          activeHit={activeHit}
          indices={indices}
          isPreviewVisible={isPreviewVisible}
          onResultClick={onResultClick}
          setActiveHit={_setActiveHit}
        />
      </div>

      <div className="SearchResult__search-result-preview">
        <ContentPreview activeHit={activeHit} onResultClick={onResultClick} />
      </div>
    </div>
  );
};

export default SearchResult;
