import React from 'react';

import './Footer.css';

const { version: appVersion } = require('../../../../package.json');

const Footer = ({ siteTitle }) => (
    <footer className="Footer">
        <p title={"Sequencing v" + appVersion}>
            © {new Date().getFullYear()}
            {` `}
            {siteTitle}
        </p>
    </footer>
);

export default Footer;
