module.exports = {
  componentTags: {
    button: `CtaButton`,
    newWindowLink: `NewWindowLink`,
    video: `VideoPlayer`,
  },
  drupalEndpointConfiguration: {
    headers: {
      Authorization: `Bearer 5sfRveFXyKwFI36`,
    },
  },
};
