// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-04-templates-blog-category-js": () => import("./../../../src/components/04-templates/blog/category.js" /* webpackChunkName: "component---src-components-04-templates-blog-category-js" */),
  "component---src-components-04-templates-blog-post-js": () => import("./../../../src/components/04-templates/blog/post.js" /* webpackChunkName: "component---src-components-04-templates-blog-post-js" */),
  "component---src-components-04-templates-blog-tag-js": () => import("./../../../src/components/04-templates/blog/tag.js" /* webpackChunkName: "component---src-components-04-templates-blog-tag-js" */),
  "component---src-components-04-templates-books-landing-page-js": () => import("./../../../src/components/04-templates/books/landing-page.js" /* webpackChunkName: "component---src-components-04-templates-books-landing-page-js" */),
  "component---src-components-04-templates-books-post-js": () => import("./../../../src/components/04-templates/books/post.js" /* webpackChunkName: "component---src-components-04-templates-books-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-posts-js": () => import("./../../../src/pages/blog/posts.js" /* webpackChunkName: "component---src-pages-blog-posts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

