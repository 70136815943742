import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import Text from '../../01-atoms/Text/Text';

import './ContentMap.css';

const makeMenuOutOfContentMap = (contentMap, location, onLinkClick) => (
  <ol>
    {contentMap.map((item) => {
      let children;
      if (item.children?.length) {
        children = makeMenuOutOfContentMap(
          item.children,
          location,
          onLinkClick
        );
      }
      return (
        <li
          className={classnames(`ContentMap__item`, {
            'ContentMap__item--active':
              item._internalPath === location?.pathname,
          })}
        >
          <Link onClick={onLinkClick} to={item._internalPath}>
            {item.childMdx.frontmatter.title}
          </Link>
          {children}
        </li>
      );
    })}
  </ol>
);

const ContentMap = ({
  contentMap,
  label,
  location,
  onLinkClick,
  semanticLevel = 1,
}) => {
  const menu = useMemo(
    () => makeMenuOutOfContentMap(contentMap, location, onLinkClick),
    [contentMap, location, onLinkClick]
  );

  return (
    <div className="ContentMap">
      {!!label && (
        <Text className="ContentMap__label" semanticLevel={semanticLevel}>
          {label}
        </Text>
      )}
      {menu}
    </div>
  );
};

export default ContentMap;
