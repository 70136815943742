/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';
import { HeadProvider } from 'react-head';

import 'typeface-roboto';

import { AnalyticsProvider } from './src/contexts/AnalyticsContext';
import LayoutWithSidebars from './src/components/04-templates/LayoutWithSidebars/LayoutWithSidebars';

export const wrapPageElement = ({ element, props }) => (
  <LayoutWithSidebars {...props}>{element}</LayoutWithSidebars>
);

export const wrapRootElement = ({ element }) => (
  <HeadProvider>
    <AnalyticsProvider>{element}</AnalyticsProvider>
  </HeadProvider>
);
