import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';

import ContentMap from '../../01-atoms/ContentMap/ContentMap';
import Layout from '../../04-templates/Layout/Layout';
import Search from '../../03-organisms/Search/Search';
import SidebarredContent from '../../01-atoms/SidebarredContent/SidebarredContent';
import '@sequencing/design-system/dist/index.css';

const LayoutWithSidebars = (props) => {
  const isSidebarred = !!props?.pageContext?.contentMap?.length;

  /**
   * "Toggled" is not the same as "collapsed" or "expanded", since the
   * behavior changes between different breakpoints (i.e. the same
   * `true` toggled state might mean that the sidebar is collapsed on
   * one breakpoint, but expanded on another. Do not use "collapsed"
   * state for this node.
   */
  const [isSidebarAtTheStartToggled, setIsSidebarAtTheStartToggled] = useState(
    false
  );

  const prevPathFirstPartRef = useRef();
  const sidebarAtTheStartContentRef = useRef();
  const sidebarAtTheStartContentContainerRef = useRef();

  useEffect(() => {
    const firstPart = props.path.split(`/`)[1];
    const previousFirstPart = prevPathFirstPartRef.current;
    if (previousFirstPart && firstPart !== previousFirstPart) {
      scrollSidebarAtTheStartToStart();
    }
    prevPathFirstPartRef.current = firstPart;
  }, [props.path]);

  const scrollSidebarAtTheStartToStart = () => {
    if (sidebarAtTheStartContentRef.current) {
      sidebarAtTheStartContentRef.current.scrollTop = 0;
    }
    if (sidebarAtTheStartContentContainerRef.current) {
      sidebarAtTheStartContentContainerRef.current.scrollTop = 0;
    }
  };

  const toggleSidebarAtTheStart = (value) => {
    const newValue = !!(value ?? !isSidebarAtTheStartToggled);
    if (isSidebarAtTheStartToggled !== newValue) {
      setIsSidebarAtTheStartToggled(newValue);
    }
  };

  const toggleSidebarAtTheStartToFalse = () => toggleSidebarAtTheStart(false);

  if (isSidebarred) {
    return (
      <Layout location={props.location}>
        <SidebarredContent
          isSidebarAtTheStartToggled={isSidebarAtTheStartToggled}
          sidebarAtTheStart={
            <>
              <Search
                inputPlaceholder={props.pageContext.searchInputPlaceholder}
                onTriggerClick={toggleSidebarAtTheStartToFalse}
                searchIndices={props.pageContext.searchIndices}
                triggerLabel={props.pageContext.searchTriggerLabel}
              />
              <ContentMap
                contentMap={props.pageContext.contentMap}
                label={
                  <Link
                    onClick={toggleSidebarAtTheStartToFalse}
                    to={props.pageContext.contentMapLabelUrl}
                  >
                    {props.pageContext.contentMapLabel}
                  </Link>
                }
                location={props.location}
                onLinkClick={toggleSidebarAtTheStartToFalse}
                semanticLevel={2}
              />
            </>
          }
          sidebarAtTheStartContentContainerRef={
            sidebarAtTheStartContentContainerRef
          }
          sidebarAtTheStartContentRef={sidebarAtTheStartContentRef}
          toggleSidebarAtTheStart={toggleSidebarAtTheStart}
          toggleSidebarAtTheStartToFalse={toggleSidebarAtTheStartToFalse}
        >
          {props.children}
        </SidebarredContent>
      </Layout>
    );
  }

  return <Layout location={props.location}>{props.children}</Layout>;
};

export default LayoutWithSidebars;
