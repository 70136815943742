import React, { createRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Configure, InstantSearch, PoweredBy } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import Modal from 'react-modal';
import cn from 'classnames';
import SearchBox from './SearchBox';
import SearchResult from './SearchResult';

import './Search.css';

const Search = ({
  inputPlaceholder,
  onTriggerClick,
  searchIndices,
  triggerLabel,
}) => {
  const rootRef = createRef();
  const [activeHit, _setActiveHit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState();
  const searchClient = algoliasearch(
    `9ZBRU7FLQT`,
    `de657af9c7f3b779853dc9156a2302c7`
  );

  const setActiveHit = (hit) => {
    if (!activeHit || hit.objectID !== activeHit.objectID) {
      _setActiveHit(hit);
    }
  };

  const clearRefinementsRef = useRef();

  const closeModal = () => {
    clearRefinementsRef?.current?.click();
    setActiveHit(false);
    isModalOpen && setIsModalOpen(false);
    document.documentElement?.classList.remove(`overflow-hidden`);
  };

  const openModal = () => {
    !isModalOpen && setIsModalOpen(true);
    document.documentElement?.classList.add(`overflow-hidden`);
  };

  const _onTriggerClick = (...args) => {
    typeof onTriggerClick === `function` && onTriggerClick(...args);
    openModal(...args);
  };

  return (
    <div className={cn("Search", {"Search--simple": !triggerLabel})} ref={rootRef}>
      {triggerLabel ? (
        <button className="Search__cta" onClick={_onTriggerClick}>
          {triggerLabel}
        </button>
      ) : (
        <div className={"Search__icon"} onClick={_onTriggerClick}/>
      )}
      <Modal
        className="Search__modal"
        contentLabel="Search modal"
        isOpen={!!isModalOpen}
        onRequestClose={closeModal}
        overlayClassName="Search__modal-overlay"
      >
        <InstantSearch
          indexName={searchIndices[0].name}
          onSearchStateChange={({ query }) => {
            setActiveHit(false);
            setQuery(query);
          }}
          searchClient={searchClient}
        >
          <Configure clickAnalytics={true} />
          <aside className="Search__modal-body">
            <header className="Search__modal-header">
              <SearchBox
                className="Search__search-box"
                clearRefinementsRef={clearRefinementsRef}
                placeholder={inputPlaceholder}
                setActiveHit={setActiveHit}
              />
              <div className="Search__modal-actions">
                <button
                  className="Search__modal-close"
                  onClick={() => {
                    closeModal();
                  }}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </header>

            {!!query?.length && (
              <>
                <div className="Search__modal-content">
                  <SearchResult
                    activeHit={activeHit}
                    indices={searchIndices}
                    onResultClick={() => {
                      dataLayer.push({ event: `View Details` });
                      closeModal();
                    }}
                    setActiveHit={setActiveHit}
                  />
                </div>
                <footer className="Search__modal-footer">
                  <div className="Search__legend" />
                  <div className="Search__powered-by">
                    <PoweredBy />
                  </div>
                </footer>
              </>
            )}
          </aside>
        </InstantSearch>
      </Modal>
    </div>
  );
};

Search.propTypes = {
  searchIndices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  triggerLabel: PropTypes.string.isRequired,
};

export default Search;
