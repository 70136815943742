import React, { createContext, useEffect } from 'react';
import Cookies from 'js-cookie';

import withLocation from '../HOCs/withLocation';

const AnalyticsContext = createContext();

const COOKIE_DURATIONS = {
  '24h': 1,
  '28d': 28,
  '10y': 3650,
};

const setLandingCookiesWithDuration = (key, value) => {
  Object.keys(COOKIE_DURATIONS).forEach((durationKey) => {
    const cookieName = `${key}_${durationKey}`;
    if (!Cookies.get(cookieName)) {
      Cookies.set(cookieName, value, {
        expires: COOKIE_DURATIONS[durationKey],
      });
    }
  });
};

const removeLandingCookiesWithDuration = (key) => {
  Object.keys(COOKIE_DURATIONS).forEach((durationKey) => {
    const cookieName = `${key}_${durationKey}`;
    Cookies.remove(cookieName);
  });
};

const setLandingCookies = (location) => {
  const COOKIE_NAME_REFERRER = `seq_landing_referrer`;
  const COOKIE_NAME_UA = `seq_landing_ua`;
  const COOKIE_NAME_URL = `seq_landing_url`;

  const amItheReferrer = document.referrer
    .replace(`http://`, ``)
    .replace(`https://`, ``)
    .split(`/`)[0]
    .match(new RegExp(`.*` + location.host.replace(`www.`, ``)));

  try {
    /**
     * This removes the previously used cookie named `COOKIE_NAME_UA`.
     */
    removeLandingCookiesWithDuration(COOKIE_NAME_UA);
  } catch (err) {}

  if (amItheReferrer) {
    return;
  }

  try {
    setLandingCookiesWithDuration(
      COOKIE_NAME_REFERRER,
      document.referrer || location.origin
    );
  } catch (err) {}

  try {
    setLandingCookiesWithDuration(
      COOKIE_NAME_URL,
      `${location.pathname}${location.search}`
    );
  } catch (err) {}
};

const AnalyticsProvider = withLocation((props) => {
  useEffect(() => {
    setLandingCookies(props.location);
  }, [props.location]);

  return (
    <AnalyticsContext.Provider>{props.children}</AnalyticsContext.Provider>
  );
});

export { AnalyticsContext, AnalyticsProvider };
