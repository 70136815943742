import { useEffect, useState } from 'react';

/**
 * This supports SSR and hydration, as opposed to `useMediaQuery` of
 * `beautiful-react-hooks`. Gatsby has an issue with different markup
 * betw/ client- and server-side.
 */

const useMediaQuery = (query) => {
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    const handleMediaQueryChange = () => {
      setIsMatch(!!mediaQueryList.matches);
    };

    handleMediaQueryChange();
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener(`change`, handleMediaQueryChange);
    } else {
      mediaQueryList.addListener(handleMediaQueryChange);
    }
    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener(`change`, handleMediaQueryChange);
      } else {
        mediaQueryList.removeListener(handleMediaQueryChange);
      }
    };
  }, [query]);

  return isMatch;
};

export default useMediaQuery;
