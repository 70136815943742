import React from 'react';

const getTag = (semanticLevel) => {
  const int = parseInt(semanticLevel, 10);
  if (typeof int === `number` && int > 0 && int < 7) {
    return `h${int}`;
  }
  return `p`;
};

const Text = ({ children, className, semanticLevel = `p` }) => {
  const Tag = getTag(semanticLevel);

  return <Tag className={className}>{children}</Tag>;
};

export default Text;
