import React from 'react';

const ContentNewWindowLink = ({ children, href, rel, title }) => (
  <a
    href={href}
    rel="noopener"
    target="_blank"
    title={!!title && title.length ? title : undefined}
  >
    {children}
  </a>
);

export default ContentNewWindowLink;
