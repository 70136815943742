import React, { useLayoutEffect, useRef } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

export default connectSearchBox(
  ({
    className,
    clearRefinementsRef,
    currentRefinement,
    onFocus,
    placeholder,
    refine,
    setActiveHit,
  }) => {
    const inputRef = useRef();

    useLayoutEffect(() => {
      inputRef.current.focus();
    }, []);

    return (
      <form
        action=""
        className={className}
        noValidate
        onSubmit={(e) => e.preventDefault()}
        role="search"
      >
        <i />
        <input
          aria-label="Search"
          className="SearchInput"
          onChange={(e) => {
            refine(e.target.value);
          }}
          onFocus={onFocus}
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          value={currentRefinement}
        />
        {!!currentRefinement && (
          <button
            onClick={() => {
              refine(``);
              setTimeout(() => {
                inputRef?.current?.focus();
              }, 1);
              setActiveHit(false);
            }}
            ref={clearRefinementsRef}
            type="button"
          >
            Clear all refinements
          </button>
        )}
      </form>
    );
  }
);
