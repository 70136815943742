import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import useClickOutside from '../../../hooks/useClickOutside';
import { useStickyPositionHeight } from '../../../hooks/useStickyPositionHeight';

import './SidebarredContent.css';

const SidebarredContent = ({
  children,
  isSidebarAtTheStartToggled,
  sidebarAtTheStart,
  sidebarAtTheStartContentRef,
  sidebarAtTheStartContentContainerRef,
  sidebarAtTheEnd,
  toggleSidebarAtTheStart,
  toggleSidebarAtTheStartToFalse,
}) => {
  const sidebarAtTheStartRef = useRef();
  const top = useStickyPositionHeight(`.Header`);

  useEffect(() => {
    const handleResize = () => {
      if (
        !sidebarAtTheStartRef.current ||
        !sidebarAtTheStartContentRef.current
      ) {
        return;
      }

      const headerNode = document.querySelector(`header.Header`);

      if (!headerNode) {
        return;
      }

      sidebarAtTheStartRef.current.style.top = `${headerNode.offsetHeight}px`;
      sidebarAtTheStartContentRef.current.style.top = `${headerNode.offsetHeight}px`;
    };

    if (typeof window !== `undefined`) {
      handleResize();
      window.addEventListener(`resize`, handleResize);
    }

    return () => {
      window.removeEventListener(`resize`, handleResize);
    };
  }, [sidebarAtTheStartContentRef]);

  useClickOutside(sidebarAtTheStartRef, toggleSidebarAtTheStartToFalse);

  return (
    <section className="SidebarredContent">
      <div className="SidebarredContent__container">
        <aside
          className={classnames(
            `SidebarredContent__sidebar SidebarredContent__sidebar--start`,
            {
              'SidebarredContent__sidebar--toggled': isSidebarAtTheStartToggled,
            }
          )}
          ref={sidebarAtTheStartRef}
          style={{ top }}
        >
          <a
            className="SidebarredContent__skip-to-content-link"
            href="#content"
          >
            Skip to main content
          </a>
          <button
            className="SidebarredContent__toggle-trigger"
            onClick={() => toggleSidebarAtTheStart()}
            tabindex="0"
            title="Expand or collapse the Table of Contents"
          >
            <span>Table of Contents</span>
          </button>
          <div
            className="SidebarredContent__sidebar-content"
            ref={sidebarAtTheStartContentRef}
          >
            <div
              className="SidebarredContent__sidebar-content-container"
              ref={sidebarAtTheStartContentContainerRef}
            >
              {sidebarAtTheStart}
            </div>
          </div>
        </aside>

        <div className="SidebarredContent__content" id="content" tabindex="-1">
          {children}
        </div>

        <aside className="SidebarredContent__sidebar SidebarredContent__sidebar--end">
          {sidebarAtTheEnd}
        </aside>
      </div>
    </section>
  );
};

export default SidebarredContent;
