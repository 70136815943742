import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Search from '../../03-organisms/Search/Search';
import slugify from '../../../utilities/slugify';
import { Header as SDSHeader, Button } from '@sequencing/design-system';

import './Header.css';
import config from '../../../../scripts/utils/config';

const defaultSearchIndices = [
  { name: `Blog`, title: `Blog` },
  { name: `Education Center`, title: `Education Center` },
  { name: `Knowledge Center`, title: `Knowledge Center` },
];

const CategoryLink = ({ children, location, url }) => {
  if (url === location?.pathname) {
    return (
      <Link aria-current="page" to={url}>
        {children}
      </Link>
    );
  }

  return <Link to={url}>{children}</Link>;
};

const anonNavData = [
  {
    path: `/`,
    label: `Our Difference`,
    items: [
      {
        label: `Whole Genome Sequencing`,
        path: `/our-difference/whole-genome-sequencing`,
      },
      {
        label: `Personalized Health`,
        path: `/our-difference/personalized-health`,
      },
      {
        label: `Health Scan`,
        path: `/products/sequencing-health-scan`,
      },
      {
        label: `Privacy Forever`,
        path: `/our-difference/privacy-forever`,
      },
      {
        label: `Testimonials`,
        path: `/testimonials`,
      },
    ],
  },
  {
    label: 'Support',
    path: '',
    items: [
      {
        label: 'Knowledge Center',
        url: 'https://sequencing.com/knowledge-center',
      },
      {
        label: 'Education Center',
        url: 'https://sequencing.com/education-center',
      },
      {
        label: 'Contact Support',
        url: '/support',
      },
    ],
  },
];

const userNavData = [
  {
    path: '/dashboard',
    label: 'Return To Dashboard',
  },
];

const anonymousAuxiliaryLinks = [
  {
    label: 'Upload DNA Data',
    path: '/data/upload',
  },
  {
    label: 'Sign In',
    path: '/sign-in',
  }
];

const Header = ({ location }) => {
  const searchIndices = useMemo(() => {
    const splitPath = location.pathname.split(`/`);
    if (splitPath[1] === `education-center`) {
      return [
        { name: `Education Center`, title: `Education Center` },
        { name: `Knowledge Center`, title: `Knowledge Center` },
        { name: `Blog`, title: `Blog` },
      ];
    }
    if (splitPath[1] === `knowledge-center`) {
      return [
        { name: `Knowledge Center`, title: `Knowledge Center` },
        { name: `Education Center`, title: `Education Center` },
        { name: `Blog`, title: `Blog` },
      ];
    }
    if (splitPath[1] === `blog`) {
      return [
        { name: `Blog`, title: `Blog` },
        { name: `Education Center`, title: `Education Center` },
        { name: `Knowledge Center`, title: `Knowledge Center` },
      ];
    }
    return defaultSearchIndices;
  }, [location.pathname]);

  const {
    categoriesData: { categories },
  } = useStaticQuery(
    graphql`
      query {
        categoriesData: allFile(
          filter: {
            sourceInstanceName: { eq: "blog" }
            absolutePath: { regex: "//markdown//" }
          }
        ) {
          categories: group(field: childMdx___frontmatter___categories) {
            fieldValue
          }
        }
      }
    `
  );

  const userStore = typeof window !== "undefined" && window.localStorage.getItem("user_store");
  const userData = userStore && JSON.parse(userStore)?.state.user;
  const navData = userData ? userNavData : anonNavData;

  const logout = async () => {
    await fetch(
      `${process.env.GATSBY_BASE_SITE_URL}/api/sequencing/public/user/logout`,
      {
        ...config.drupalEndpointConfiguration,
        method: "POST",
      }
    )
      .then(() => {
        localStorage.removeItem('user_store');
        window.location.href = '/';
      })
      .catch(console.error);
  };

  return (
    <div className={`Header`}>
      <SDSHeader
        user={
          userData
            ? {
              name: userData.field_first_name.default,
              avatarImageUrl: userData.avatar,
            }
            : undefined
        }
        onRequestSignOut={logout}
        customClass={`SDSHeader`}
        myAccountUrl="/account/personal-information"
        onRequestSignIn={() => (window.location.href = `/sign-in?next=${location.pathname}`)}
        onRequestMyAccount={() => (window.location.href = '/account/personal-information')}
        onRequestHomePage={() => (window.location.href = isUserLoggedIn ? '/dashboard' : '/')}
        searchComponent={
          !userData &&
          <Search
            inputPlaceholder={`Search Sequencing.com`}
            searchIndices={searchIndices}
          />
        }
        mainLinks={navData}
        auxiliaryLinks={!userData && anonymousAuxiliaryLinks}
        onLinkClick={(url) => (window.location.href = url)}
        loggedOutButton={
          <Button
            label="Shop All"
            onClick={() =>
              (window.location.href = `https://dna.sequencing.com/shop-all-bundles/`)
            }
          />
        }
      />
      <ul className="Header__categories">
        <li>
          <CategoryLink location={location} url="/blog">
            Recent
          </CategoryLink>
        </li>
        {categories.map((category) => {
          const slug = slugify(category.fieldValue);
          return (
            <li key={slug}>
              <CategoryLink location={location} url={`/blog/category/${slug}`}>
                {category.fieldValue}
              </CategoryLink>
            </li>
          );
        })}
        <li>
          <CategoryLink location={location} url="/education-center">
            Education Center
          </CategoryLink>
        </li>
        <li>
          <CategoryLink location={location} url="/knowledge-center">
            Knowledge Center
          </CategoryLink>
        </li>
      </ul>
    </div>
  );
};

export default Header;
